import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import ViewStore from './stores/ViewStore'
import AuthStore from './stores/AuthStore'
import FamilyStore from './stores/FamilyStore'
import CustomRouter from './stores/CustomRouter'
import UserStore from './stores/UserStore'
import MyListStore from './stores/MyListStore'
import { config as dotEnvConfig } from 'dotenv';
// Mobx Router
import { Provider } from "mobx-react"


// Load ENV variables
dotEnvConfig({
  path: process.env.NODE_ENV === 'production' ? '.env.production' : '.env'
});

const app = (
  <Provider
    ViewStore={ViewStore}
    AuthStore={AuthStore}
    CustomRouter={CustomRouter}
    UserStore={UserStore}
    MyListStore={MyListStore}
    FamilyStore={FamilyStore}
  >
    <App />
  </Provider>
);


ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  app,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
