import React, { Component } from 'react';
import { getUsersForAdmin } from '../api_calls/user';
import DataTable from 'react-data-table-component';
import { observer } from "mobx-react";
import CustomRouter from '../stores/CustomRouter';
import NavViews from '../stores/NavViews';


const columns = [
    {        
        dataIndex: '_id',
        key: '_id',
        sortable: true,
        name: 'ID',
        selector: '_id',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: row._id}) } style={{ fontSize: "12pt" }}>{row._id}</span>,
    }, 
    {        
        dataIndex: 'name',
        key: 'name',
        sortable: true,
        name: 'Name',
        selector: 'name',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: row._id}) } style={{ fontSize: "12pt" }}>{row.name}</span>,
    },  
    {        
        dataIndex: 'email',
        key: 'email',
        sortable: true,
        name: 'email',
        selector: 'email'        
    },
    {        
        dataIndex: 'groups',
        key: 'groups',
        sortable: true,
        name: 'groups',
        selector: 'groups',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: row._id}) } style={{ fontSize: "12pt" }}>{row.groups.map(g => g.name).join(", ")}</span>,
    },
    {        
        dataIndex: 'date',
        key: 'date',
        sortable: true,
        name: "Date Created",
        selector: 'date',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: row._id}) } style={{ fontSize: "12pt" }}>{new Date(row.createdAt).toLocaleDateString()}</span>,
    },
    
];


@observer(["UserStore", "ViewStore", "CustomRouter", "AuthStore", "FamilyStore"])
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    componentDidMount(){
        const {AuthStore} = this.props;
        let token = AuthStore.userAuth.auth.accessToken;
        getUsersForAdmin(token).then((res) => {
            console.log(res);
            const users = res;
            if (users)  {
                this.setState({tableData: users});
            }
        });
    }
    render() {
        return (<div>
             <DataTable
                defaultSortField="name"
                defaultSortAsc={false}
                columns={columns}
                data={this.state.tableData}
                theme="dark"
                pagination
            />
        </div>
        );
    }
}

export default Users;