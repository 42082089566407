import React, { Component } from 'react';
import { getUserCount, getGroupCount, getSessionsForLastWeek } from "../api_calls/user";
import SessionLogs from './SessionLogs';
import { getGiftCount } from "../api_calls/gift";
import { Bar } from 'react-chartjs-2';

import { observer } from "mobx-react";

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};


@observer(["UserStore", "ViewStore", "CustomRouter", "AuthStore", "FamilyStore"])
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { userCount: "-", sessions: {} };
    }
    componentDidMount() {
        const { AuthStore } = this.props;
        let token = AuthStore.userAuth.auth.accessToken;
        getUserCount(token).then((res) => {
            this.setState({ userCount: res.count });
        });

        getGroupCount(token).then((res) => {
            this.setState({ groupCount: res.count });
        });

        getSessionsForLastWeek(token).then((res) => {
            this.setState({ sessions: res });
        })

        // getGiftCount(token).then((res) => {
        //     this.setState({ giftCount: res.count });
        // });
    }
    componentDidUpdate() { }
    render() {
        return (<div>
            <div className="AppFont"> Users: <span className="NoteFont">{this.state.userCount}</span> </div>
            <div className="AppFont"> Groups: <span className="NoteFont">{this.state.groupCount}</span> </div>
            {/* <div className="AppFont"> Gifts: <span className="NoteFont">{this.state.giftCount}</span> </div> */}
            <div>
                <SessionLogs sessions={this.state.sessions} />
            </div>
            <div style={{width:"50%", marginLeft: "20%"}}>
                <Bar
                data={
                    {
                        labels: ['Users', 'Groups'/** , 'Gifts'*/],
                        datasets: [
                            {
                                label: "#",
                                data: [this.state.userCount, this.state.groupCount, /**this.state.giftCount*/],
                                backgroundColor: [                                 
                                  'rgba(54, 162, 235, 0.2)',                                  
                                  'rgba(75, 192, 192, 0.2)',
                                //   'rgba(153, 102, 255, 0.2)',                               
                                ],
                                borderColor: [                                 
                                  'rgba(54, 162, 235, 1)',                                 
                                  'rgba(75, 192, 192, 1)',
                                //   'rgba(153, 102, 255, 1)',                                  
                                ],
                                borderWidth: 1,
                              },
                        ],                      
                    }
                } options={options} />
            </div>
        </div>
        );
    }
}

export default Dashboard;