import React, { Component } from 'react';
import { observer } from "mobx-react";
import Modal from 'react-modal';
import { sha512 } from 'hash-anything';
import { Button, Tooltip, Input, Menu, Icon, Layout, Drawer, Select, Checkbox, Affix, message } from "antd";
import { FaSignOutAlt, FaCog, FaClipboard } from "react-icons/fa";
import NavViews from '../stores/NavViews';
// import ChildAccounts from './ChildAccounts';
import { updateProfileImageLocation, changePassword } from "../api_calls/user";
import ImageUploader from 'react-images-upload';
import AWS from "aws-sdk";

const allowedImageTypes = ["image/jpeg"];
const maxImageSize = 500000;

const { TextArea } = Input;

var albumBucketName = "listgifts-pics";
var bucketRegion = "us-east-2";
var IdentityPoolId = "us-east-2:e467c664-233b-4e2b-ac1f-36d6fb672768";

AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
    })
});

var s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    params: { Bucket: albumBucketName, ContentType: 'image/jpeg' },
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY
});

const { Header } = Layout;
const { Option } = Select;
@observer(["UserStore", "ViewStore", "CustomRouter", "AuthStore", "FamilyStore"])
class MyHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            selectedKey: "1",
            pictures: [],
            settingsVisible: false,
            drops: 0,
            childOptionsVisible: false,
            uploadingImage: false,
            modalOpen: false
        };
        this.onDrop = this.onDrop.bind(this);
        this.onSettingsClicked = this.onSettingsClicked.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChildOptionsChange = this.onChildOptionsChange.bind(this);

        this.addPhoto = this.addPhoto.bind(this);
        this.deletePhoto = this.deletePhoto.bind(this);
        this.onSubmitPassChangeClick = this.onSubmitPassChangeClick.bind(this);
    }

    deletePhoto(albumName, photoKey) {
        s3.deleteObject({ Key: photoKey }, function (err, data) {
            if (err) {
                return alert("There was an error deleting your photo: ", err.message);
            }
            alert("Successfully deleted photo.");
            this.viewAlbum(albumName);
        });
    }

    addPhoto(file, albumName) {
        const { UserStore, AuthStore } = this.props;
        let userId = UserStore.user._id;
        var fileName = file.name;
        var albumPhotosKey = encodeURIComponent(albumName) + "/";
        var photoKey = albumPhotosKey.concat(userId).concat(".jpg");
        let self = this;       

        var upload = s3.upload({
            Key: photoKey,
            Body: file
        });

        var promise = upload.promise();

        promise.then(
            function (data) {
                self.setState({ uploadingImage: false });
                message.info("Successfully uploaded photo!");
                UserStore.setProfileImage(data.Location);

                updateProfileImageLocation(data.Location, UserStore.user._id, AuthStore.userAuth.auth.accessToken).then(() => {
                    self.setState({ pictures: [] });
                    return UserStore.refreshUser(AuthStore.userAuth.auth.accessToken);
                })
            },
            function (err) {
                self.setState({ uploadingImage: false });
                message.error("There was an error uploading your photo...");
                console.error(err.message);
            }
        );
    }

    componentWillMount() {
        if (this.props.CustomRouter.router.currentPath === "" || this.props.CustomRouter.router.currentPath === "/") {
            this.setState({ selectedKey: "1" });
        }
        if (this.props.CustomRouter.router.currentPath === "/groups") {
            this.setState({ selectedKey: "2" });
        }
        if (this.props.CustomRouter.router.currentPath === "/events") {
            this.setState({ selectedKey: "3" });
        }
        if (this.props.CustomRouter.router.currentPath === "/my-notes") {
            this.setState({ selectedKey: "4" });
        }        
    }

    componentDidMount() {
        this.setState({ childOptionsVisible: this.props.UserStore.user.childOptionsVisible });
    }

    onSettingsClicked() {
        this.setState({ settingsVisible: true, childOptionsVisible: this.props.UserStore.user.childOptionsVisible });
        const imageFile = this.props.UserStore.profileImage;
        var imgtag = document.getElementsByClassName("profile-img-settings");
        setTimeout(() => {
            for (let item of imgtag) {
                item.src = imageFile;
            }
        }, 50);
    }

    onClose() {
        this.setState({ settingsVisible: false });
    }

    onDrop(picture) {
        const { UserStore, AuthStore } = this.props;
        this.setState({
            drops: this.state.drops + 1
        });
        var selectedFile = this.state.drops - 1 > 0 ? this.state.pictures.concat(picture)[0] : this.state.pictures.concat(picture)[this.state.pictures.concat(picture).length - 1];

        let self = this;

        if (selectedFile && selectedFile.type && allowedImageTypes.includes(selectedFile.type)) {
            var reader = new FileReader();
            var imgtag = document.getElementsByClassName("profile-img")[0];
            if (imgtag) {
                imgtag.title = selectedFile.name;
            }
            if (selectedFile.size && (selectedFile.size < maxImageSize)) {
                this.setState({ uploadingImage: true });
                self.addPhoto(selectedFile, "profile-images");
            } else {
                message.warn("That image is too big!");
            }
        } else if (selectedFile && selectedFile.type && !allowedImageTypes.includes(selectedFile.type)) {
            message.warn("That file type is not supported! Try a '.jpg'");
        } else {
            message.warn("Sorry, that didn't work. Maybe the file is too big or the wrong type");
        }
    }

    onChange(value) {
        this.props.FamilyStore.setActiveGroup(value);
    }

    onChildOptionsChange(e) {
        this.props.UserStore.setChildOptionsVisible(e.target.checked);
    }

    onSubmitPassChangeClick() {
        const { UserStore, AuthStore } = this.props;
        const auth = AuthStore.userAuth.auth.accessToken;
        const passHash = this.state.hashPass;
        const newPassHash = this.state.newHashPass;
        const newPassHashConf = this.state.newHashPassConfirm;
        
        if (!newPassHash || !newPassHashConf || !passHash) {
            message.warn("Please complete all fields");
        }
        else if (newPassHashConf !== newPassHash) {
            message.warn("New password and confirmation do not match!");
        }
        else {
            changePassword(UserStore.user._id, passHash, newPassHash, auth).then((res) => {
                this.setState({ modalOpen: false });
            });
        }
    }

    render() {
        const { CustomRouter, UserStore } = this.props;
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        return (<div>

            <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                isOpen={this.state.modalOpen}
            >
                <div>
                    <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">Current Password:</span>
                    <br />
                    <Input className="password-spoof" onChange={(e) => { this.setState({ hashPass: sha512(e.target.value) }) }} />
                    <br />
                    <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">New Password:</span>
                    <br />
                    <Input className="password-spoof" onChange={(e) => { this.setState({ newHashPass: sha512(e.target.value) }) }} />
                    <br />
                    <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">Confirm New Password:</span>
                    <br />
                    <Input className="password-spoof" onChange={(e) => { this.setState({ newHashPassConfirm: sha512(e.target.value) }) }} />
                    <br />
                    <br />

                    <Button className="AppFont" style={{ color: "white", backgroundColor: "#F6D55C" }} onClick={this.onSubmitPassChangeClick.bind(this)} size="large"><Icon type="check-circle" /> Submit</Button>
                    <Button className="AppFont" style={{ color: "white", backgroundColor: "#ED553B" }} onClick={() => { this.setState({ modalOpen: false }) }} size="large"><Icon type="close" /> Cancel</Button>
                </div>
            </Modal>
            <Affix offsetTop={0}>

                <Header>
                    <div className="logo" />
                    {/* <img alt="Logo" style={{ position: "absolute", top: "2px", left: "2px" }} width="45px" height="45px" src={require("../assets/gift-flat.png")} /> */}
                    {UserStore.childViewActive ?
                        <div style={{ position: "fixed", backgroundColor: "red", width: "100%", top: "0px", height: "14px", left: 0, zIndex: 1 }}>
                            <span style={{ color: "white", position: "relative", bottom: "25px" }}>Viewing as: {UserStore.activeChild.name}</span>
                        </div> : null
                    }
                    {/* <img className="test-img" /> */}
                    {vw < 420 ?
                        <div style={{ position: "absolute", zIndex: 999, top: "50px", right: "1%" }}>
                            <Button onClick={this.onSettingsClicked} style={{ margin: "2px" }} >
                                <FaCog />
                            </Button>
                        </div>
                        :
                        <div style={{ position: "absolute", top: "1px", right: "3%" }}>
                            <Button onClick={this.onSettingsClicked} style={{ margin: "2px" }} >
                                <FaCog />
                            </Button>
                        </div>
                    }
                    <Drawer
                        title="Settings"
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.settingsVisible}
                    >
                        <div style={{ color: "#0c273b", fontSize: "36pt", marginBottom: "50px" }} className="AppFont">
                            {/* <Avatar  className="profile-img" size="small" shape="circle" src={UserStore.user.imageLink} /> */}
                            <img alt="" className="profile-img-settings" style={{ width: "50px", height: "50px", borderRadius: "30px" }} />
                            {this.props.UserStore.user.name} <br />
                        </div>
                        {this.props.UserStore.user.groups.length < 2 ? null :
                            <div>
                                Active Group
                            <hr />
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Active Group"
                                    optionFilterProp="children"
                                    onChange={this.onChange}
                                    defaultValue={this.props.FamilyStore.activeGroupId}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {this.props.UserStore.user.groups.map((group) => {
                                        return <Option value={group.id}>{group.name}</Option>
                                    })}
                                </Select>
                                <br />
                            </div>
                        }
                        <br />
                        {this.state.uploadingImage ?
                            <div style={{ textAligin: "center" }}>
                                Uploading Image...
                        <Icon style={{ fontSize: "30pt" }} type="loading" />
                            </div>
                            : ""}

                        <div style={{ filter: this.state.uploadingImage ? "blur(5px)" : "", pointerEvents: this.state.uploadingImage ? "none" : "" }}>Change Profile Picture <hr />

                            <ImageUploader
                                label={`Max file size is ${maxImageSize / 1000}KB`}
                                withIcon={true}
                                buttonText='Choose image'
                                onChange={this.onDrop}
                                imgExtension={['.jpg', '.gif', '.png']}
                                maxFileSize={10000000}
                            /></div>
                        <br />
                      
                        <div>Security Options
                        <hr />
                            <Button onClick={() => this.setState({ modalOpen: true })}>Change Password</Button>
                        </div>
                        <br />
                        <div>Log Out <hr />
                            <Button style={{ margin: "2px" }} onClick={this.props.onLoggedOut} >
                                <Tooltip title="log out"><FaSignOutAlt /></Tooltip></Button>
                        </div>


                    </Drawer>
                    {this.props.UserStore.role === "guest" ? null :
                        <Menu
                            defaultSelectedKeys={[this.state.selectedKey]}
                            defaultOpenKeys={[]}
                            mode="horizontal"
                            style={{ lineHeight: '64px' }}
                            theme="dark"
                            className="topBarMenu"
                        //style={{ position: "absolute", top: "12%", left: "12%" }}
                        >
                            <Menu.Item onClick={() => CustomRouter.router.goTo(NavViews.home)} key="1">
                                <Icon type="bars" />
                                <span>Dashboard</span>
                            </Menu.Item>
                            <Menu.Item onClick={() => CustomRouter.router.goTo(NavViews.groups)} key="2">
                                <Icon type="team" />
                                <span>Groups</span>
                            </Menu.Item>
                            <Menu.Item onClick={() => CustomRouter.router.goTo(NavViews.users)} key="3">
                                <Icon type="calendar" />
                                <span>Users</span>
                            </Menu.Item>
                            {/* <Menu.Item onClick={() => CustomRouter.router.goTo(NavViews.myNotes)} key="4">
                                <FaClipboard style={{ marginRight: '5px' }} />
                                <span>Notes</span>
                            </Menu.Item> */}
                        </Menu>
                    }
                </Header>
            </Affix>
        </div>
        )
    }
}
export default MyHeader;