import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getGroupForAdmin, getUsersForGroupAdmin } from '../api_calls/user';
import _ from 'underscore';
import DataTable from 'react-data-table-component';
import CustomRouter from '../stores/CustomRouter';
import NavViews from '../stores/NavViews';

const columns = [
    {        
        dataIndex: '_id',
        key: '_id',
        sortable: true,
        name: 'ID',
        selector: '_id',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: row._id}) } style={{ fontSize: "12pt" }}>{row._id}</span>,
    }, 
    {        
        dataIndex: 'name',
        key: 'name',
        sortable: true,
        name: 'Name',
        selector: 'name',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: row._id}) } style={{ fontSize: "12pt" }}>{row.name}</span>,
    },  
    {        
        dataIndex: 'email',
        key: 'email',
        sortable: true,
        name: 'email',
        selector: 'email'        
    },
    {        
        dataIndex: 'groups',
        key: 'groups',
        sortable: true,
        name: 'groups',
        selector: 'groups',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: row._id}) } style={{ fontSize: "12pt" }}>{row.groups.map(g => g.name).join(", ")}</span>,
    },
    {        
        dataIndex: 'date',
        key: 'date',
        sortable: true,
        name: "Date Created",
        selector: 'date',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: row._id}) } style={{ fontSize: "12pt" }}>{new Date(row.createdAt).toLocaleDateString()}</span>,
    },
    
];

@observer(['CustomRouter', 'MyListStore', 'UserStore', 'FamilyStore', 'AuthStore'])
class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            group: {
                _id: "",
                name: "",
                users: [],
                creator: "",
                settings: {},
                roles: []
            }
        };
    }

    componentDidMount() {
        const groupId = this.props.CustomRouter.router.params.groupId;
        const {AuthStore} = this.props;
        let token = AuthStore.userAuth.auth.accessToken;
        getGroupForAdmin(token, groupId).then((res) => {
            const group = res;
            this.setState({group});
        });
        getUsersForGroupAdmin(token, groupId).then((res) => {
            const users = res;
            console.log(users);
            if (users)  {
                this.setState({tableData: users});
            }
        });
    }
    
    render() {
        const creator = _.findWhere(this.state.tableData, { _id: this.state.group.creator}) || {};
        return (<div>
            <h1>{this.state.group.name}</h1>
            <h2 onClick = {() => CustomRouter.router.goTo(NavViews.user, {userId: creator._id}) }>Creator: {creator.name}</h2>
            <u><h3>Settings</h3></u>
            <div><p>{JSON.stringify(this.state.group.settings)}</p></div>
            <u><h3>Group Members</h3></u>
            
            <DataTable      
                defaultSortField="name"
                defaultSortAsc={false}
                columns={columns}
                data={this.state.tableData}
                theme="dark"
                pagination
            />
            
        </div>
        );
    }
}

export default Group;