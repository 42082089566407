import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

import _ from 'underscore';

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};

class SessionLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    lastSevenDays() {
        let days = [];
        for (let i = 0; i < 7; i++) {;
            days.push(new Date(new Date().getTime()  - (i * 1000 * 60 * 60 * 24)).toDateString())  ;
        }
        return days.reverse();
    }

    sessionData() { 
        let sessions = this.props.sessions;
        if (sessions.length) {
            sessions.forEach(session => {
                session.day = new Date(session.createdAt).getUTCDate();
            });    
            let groupedByDay = _.groupBy(sessions, "day");
            let lastSevenDates = [];
            for (let i = 0; i < 7; i++) {;
                lastSevenDates.push(new Date(new Date().getTime()  - (i * 1000 * 60 * 60 * 24)).getUTCDate());
            }
            lastSevenDates.forEach((date) => {
                if (!groupedByDay[date]) {
                    groupedByDay[date] = [];
                }
            });    
            return Object.keys(groupedByDay).map(key => groupedByDay[key].filter(session => session.type === "sessionStart" || session.type === "logIn").length);
        }
        return [];
    }

    render() {
        return (<div style={{width:"50%", marginLeft: "20%"}}>
                <Line  data={
                    {
                        labels: this.lastSevenDays(),
                        datasets: [
                            {   label: "Sessions",                             
                                data: this.sessionData(),
                                backgroundColor: [                                 
                                  'rgba(54, 162, 235, 0.2)',                                  
                                  'rgba(75, 192, 192, 0.2)',
                                //   'rgba(153, 102, 255, 0.2)',                               
                                ],
                                borderColor: [                                 
                                  'rgba(54, 162, 235, 1)',                                 
                                  'rgba(75, 192, 192, 1)',
                                //   'rgba(153, 102, 255, 1)',                                  
                                ],
                                borderWidth: 1,
                              },
                        ],                      
                    }
                } options={options} />
            </div>);
    }
}

export default SessionLogs;