import React, { Component } from 'react'
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { Icon, Button, Input, message, List } from 'antd';
import { sha512 } from 'hash-anything';
import { createUser } from "../api_calls/user";
import { FaUsers, FaGift, FaCalendarAlt } from 'react-icons/fa';

Modal.setAppElement('#root')
@observer(["UserStore"])
class GuestHomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            hashPass: "",
            family: "",
            registerModalIsOpen: false,
            loginModalIsOpen: false,
            buttons: <div>
                <Button style={{ marginLeft: "4px", backgroundColor: "#ED553B" }} size="large" onClick={() => { this.setState({ loginModalIsOpen: true, registerModalIsOpen: false }) }}>Log In</Button>
            </div>
        };
        this.closeLoginModal = this.closeLoginModal.bind(this);
        this.closeRegisterModal = this.closeRegisterModal.bind(this);    
        this.submitLogin = this.submitLogin.bind(this);
     
    }

    // handleAuthenticate = ({ email, hashPass }) =>
    //     fetch(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
    //         body: JSON.stringify({ email, hashPass }),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         method: 'POST'
    //     }).then(response => response.json());

    // handleClick = () => {
    //     const { onLoggedIn } = this.props;
    //     this.setState({ loading: true });
    //     let email = this.state.email;
    //     let hashPass = this.state.hashPass;

    //     // Look if user with current publicAddress is already present on backend
    //     // fetch(
    //     //     `${
    //     //     process.env.REACT_APP_BACKEND_URL
    //     //     }/users?email=${email}`
    //     // )
    //     //     .then(response => response.json())
    //     //     // If yes, retrieve it. If no, create it.
    //     //     .then(
    //     //         users => (users.length ? users[0] : this.handleSignup(email, hashPass))
    //     //     )

    //     // // Send signature to backend on the /auth route
    //     // .then(this.handleAuthenticate)
    //     // // Pass accessToken back to parent component (to save it in localStorage)
    //     // .then(onLoggedIn)
    //     // .catch(err => {
    //     //     window.alert(err);
    //     //     this.setState({ loading: false });
    //     // });
    // };

    closeRegisterModal() {
        this.setState({ registerModalIsOpen: false });
    }

    closeLoginModal() {
        this.setState({ loginModalIsOpen: false });
    }

    submitLogin() {
        const { onLoggedIn } = this.props;
        let self = this;
        let email = this.state.email;
        let passHash = this.state.hashPass;
        if (this.state.email === "") {
            message.warning("Must have email");
        } else if (this.state.hashPass === "") {
            message.warning("Must have a password");
        } else {
            this.setState({
                loginModalIsOpen: false,
                buttons: <Icon type="loading" />
            });
            fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/admin`, {
                body: JSON.stringify({ email, passHash }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }).then((response) => {
                if (response.status === 401 ) {
                    return message.error("Email or password does not match database!");
                }
                else if(response.status === 403) {
                    return message.error("Not Authorized!");
                } else {
                    return response.json().then(onLoggedIn)
                }
            })
                .catch(err => {
                    window.alert(err);
                    this.setState({ loading: false });
                });

            this.setState({
                buttons: <div>
                    <Button style={{ marginLeft: "4px", backgroundColor: "#ED553B" }} size="large" onClick={() => { self.setState({ loginModalIsOpen: true, registerModalIsOpen: false }) }}>Log In</Button>
                </div>
            });
        }
    }


 
    render() {
        const listItems = [
            <List.Item key={0} style={{color: 'white', marginBottom: '25px'}}>
                <div>                    
                    <FaCalendarAlt style={{fontSize: '5em'}} />                    
                    <div style={{fontSize: '2em'}}>REMEMBER</div>                  
                    <span>Helpful tools to remind you of events</span>
                </div>
            </List.Item>,
            <List.Item key={1} style={{color: 'white', marginBottom: '25px'}}>
                <div>
                    <FaUsers style={{fontSize: '5em'}} />                   
                    <div style={{fontSize: '2em'}}>COLLABORATE</div>                   
                    <span>Avoid duplicate gifts without the need to communicate</span>
                </div>
            </List.Item>,
            <List.Item key={2} style={{color: 'white', marginBottom: '25px'}}>
                <div>
                    <FaGift style={{fontSize: '5em'}} />                 
                    <div style={{fontSize: '2em'}}>GIFT</div>                  
                    <span>Give a gift you know they will want</span>
                </div>
            </List.Item>
        ]
        return (
            <div className="AppWelcome" >
                <div style={{
                    padding: "2px"
                }}>
                    <img alt="Logo" style={{ padding: "6px", marginBottom: "20px" }} width="125px" height="125px" src={require("../assets/gift-flat.png")} />
                    LIST.GIFTS Admin<span style={{ fontSize: "12pt" }}></span>
                    {this.state.buttons}
                </div>
                <Modal
                    isOpen={this.state.loginModalIsOpen}
                    onRequestClose={this.closeLoginModal}
                    contentLabel="Login Modal"
                    className="register-modal"
                    overlayClassName="register-modal-overlay">
                    <div className="AppFont" style={{ fontSize: "20pt", color: "white" }}>
                        <div onClick={this.closeLoginModal.bind(this)}><Icon type="close-circle" /></div>
                        email: <Input type="email" onChange={(e) => { this.setState({ email: e.target.value }) }} />
                        <br />
                        password: <Input type="password" onChange={(e) => { this.setState({ hashPass: sha512(e.target.value) }) }} />
                        <br />
                        <br />
                        <Button size="large" onClick={this.submitLogin}>Submit</Button>
                    </div>
                </Modal>
                
                    {/* <div className="list-items" style={{position: 'absolute', top: '70%', width: "100%"}}>
                    <List  style={{marginTop: '5px', backgroundColor: "white"}} grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 3, xl: 3, xxl: 3 }} >
                        {listItems}
                    </List>
                    </div> */}
                

            </div>
        )
    }
}
export default GuestHomePage;
