import React, { Component } from 'react';
import { getGroupsForAdmin } from '../api_calls/user';
import DataTable from 'react-data-table-component';
import { observer } from "mobx-react";
import CustomRouter from '../stores/CustomRouter';
import NavViews from '../stores/NavViews';


const columns = [
    {        
        dataIndex: 'name',
        key: 'name',
        sortable: true,
        name: 'Name',
        selector: 'name',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.group, { groupId: row._id }) } style={{ fontSize: "12pt" }}>{row.name}</span>,
    },
    {        
        dataIndex: 'members',
        key: 'members',
        sortable: true,
        name: "Members",
        selector: 'members',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.group, { groupId: row._id }) } style={{ fontSize: "12pt" }}>{Number(row.users.length)}</span>,
    },
    {        
        dataIndex: 'date',
        key: 'date',
        sortable: true,
        name: "Date Created",
        selector: 'date',
        cell: row => <span onClick = {() => CustomRouter.router.goTo(NavViews.group, { groupId: row._id }) } style={{ fontSize: "12pt" }}>{new Date(row.createdAt).toLocaleDateString()}</span>,
    },
    
];


@observer(["UserStore", "ViewStore", "CustomRouter", "AuthStore", "FamilyStore"])
class Groups extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    componentDidMount(){
        const {AuthStore} = this.props;
        let token = AuthStore.userAuth.auth.accessToken;
        getGroupsForAdmin(token).then((res) => {
            console.log(res);
            const groups = res;
            groups.forEach(group => {                
                group.key = group._id;
                group.members = group.users.length;                
            });         
            this.setState({tableData: groups});
        });
    }
    render() {
        return (<div>
             <DataTable
                defaultSortField="members"
                defaultSortAsc={false}
                columns={columns}
                data={this.state.tableData}
                theme="dark"
                pagination
            />
        </div>
        );
    }
}

export default Groups;