import React, { Component } from 'react'
import { MobxRouter, startRouter } from 'mobx-router';
//mobx
import { Provider } from 'mobx-react';
import CustomRouter from '../stores/CustomRouter';
//router
import NavViews from '../stores/NavViews'
startRouter(NavViews, CustomRouter);

class CoreApp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Provider store={CustomRouter}>
                <MobxRouter />
            </Provider>
        );
    }
}
export default CoreApp;