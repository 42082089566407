import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getUserForAdmin } from '../api_calls/user';
import _ from 'underscore';

const keysToSkip = ["hashPass"];

@observer(['CustomRouter', 'MyListStore', 'UserStore', 'FamilyStore', 'AuthStore'])
class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                _id: "",
                name: "",
                birthDay: "",
                birthMonth: "",
                profileImage: ""
            }
        };
    }
    componentDidMount() {
        const token = this.props.AuthStore.userAuth.auth.accessToken;
        const userId = this.props.CustomRouter.router.params.userId;
        getUserForAdmin(token, userId).then((res) => {
            this.setState({ user: res });
        });
    }
    render() {
        return (<div>
            <h1>{this.state.user.name}</h1>
            <h3>{this.state.user._id}</h3>
            <img width="300px" alt="No Image" src={this.state.user.profileImage} />
            <table style={{ marginLeft: '10%' }}>
                {_.filter(Object.keys(this.state.user), key => !keysToSkip.includes(key)).map((key) => {
                    return <tr><td>
                        {key}
                    </td>
                        <td style={{position: "absolute"}}>
                            {JSON.stringify(this.state.user[key])}
                        </td>
                    </tr>
                })}
            </table>
        </div>
        );
    }
}

export default User;