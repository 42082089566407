import React from 'react';
import { Route } from 'mobx-router';
//components
import  Dashboard from "../components/Dashboard";
// import MyList from '../components/MyList';
// import SingleList from '../components/SingleList';
// import InvitePage from '../components/InvitePage';
import Groups from '../components/Groups';
import Group from '../components/Group';
import Users from '../components/Users';
import User from '../components/User';
// import Events from '../components/Events';
// import MyNotes from '../components/MyNotes';

const NavViews = {
  home: new Route({
    path: '/',
    component: <Dashboard />
  }),
  groups: new Route({
    path: '/groups',
    component: <Groups />
  }),
  group: new Route({
    path: '/group/:groupId',
    component: <Group />,
    onEnter: (route, params, store) => {
      console.log(`entering group with params`, params);
    }
  }),
  users: new Route({
    path: '/users',
    component: <Users />
  }), 
  user: new Route({
    path: '/user/:userId',
    component: <User />,
    onEnter: (route, params, store) => {
      console.log(`entering user with params`, params);
    }
  }),
  // invitePage: new Route({
  //   path: '/invite/:inviteId',
  //   component: <InvitePage />,
  //   onEnter: (route, params, store) => {
  //     console.log(`entering page with params`, params);
  //   }
  // })
 
};
export default NavViews;