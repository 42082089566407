import User from '../models/UserModel';
import UserStore from "./UserStore";
import AuthStore from "./AuthStore";
import { types } from "mobx-state-tree";
import { getFamilyMembers } from '../api_calls/user';
import MyListStore from './MyListStore';

const Birthday = types.model({
    date: types.Date,
    name: types.string
});

const FamilyStoreModel = types
    .model('FamilyStore', {
        loading: types.boolean,
        loaded: types.boolean,
        activeGroupId: types.optional(types.string, ""),
        activeGroupName: types.optional(types.string, ""),
        family: types.optional(types.array(User), []),
        birthdays: types.optional(types.array(types.Date), []),
        bdaysUnder30: types.optional(types.array(Birthday), []),
        nextBirthday: types.optional(types.Date, new Date()),
        nextBirthdayName: types.optional(types.string, "")
    })
    .views(self => {
        return {
            isLoaded: () => self.loaded,
            isLoading: () => self.loading
        }
    })
    .actions(self => {
        return {
            setFamily: async (family, me) => {
                self.setLoading(true);
                self.setLoaded(false);
                // getFamilyMembers(family, AuthStore.userAuth.auth.accessToken).then((members) => {
                //     let membersWithoutMe = [];
                //     let birthdays = [];
                //     if (members){

                    
                //         members.forEach(member => {
                //             if (member._id !== me) {
                //                 membersWithoutMe.push(member);
                //                 let d = new Date();
                //                 d.setMonth(member.birthMonth - 1);
                //                 d.setDate(member.birthDay);
                //                 let nextYearBirthday = new Date();
                //                 nextYearBirthday.setMonth(member.birthMonth - 1);
                //                 nextYearBirthday.setDate(member.birthDay);
                //                 nextYearBirthday.setFullYear(new Date().getFullYear() + 1);
                //                 birthdays.push({ date: d, name: member.name });
                //                 birthdays.push({ date: nextYearBirthday, name: member.name });
                //             }
                //         });

                //         birthdays.sort((a, b) => {
                //             return a.date.getTime() - b.date.getTime();
                //         });
                //         let currentNextUpBday;
                //         let currentNextUpBdayName;
                //         let stillLooking = true;
                //         let stillLookingWithin30 = false;
                //         let thirtyDaysTime = 1000 * 60 * 60 * 24 * 60;
                //         let indexFlag = 0;
                //         let bdaysDateArray = [];
                //         let bdaysUnder30Array = [];
                //         birthdays.forEach((birthday, index) => {
                //             bdaysDateArray.push(birthday.date);

                //             if (stillLooking) {
                //                 if (Date.now() < birthday.date.getTime()) {
                //                     stillLooking = false;
                //                     if (birthday.date.getTime() - Date.now() < thirtyDaysTime) {
                //                         stillLookingWithin30 = true;
                //                     }
                //                     indexFlag = index;
                //                     currentNextUpBday = birthday.date;
                //                     currentNextUpBdayName = birthday.name;

                //                 }
                //             }
                //             if (stillLookingWithin30 && index > indexFlag) {
                //                 if (Date.now() + thirtyDaysTime >= birthday.date.getTime()) {
                //                     bdaysUnder30Array.push(birthday);

                //                 }

                //             }
                //         });

                //         self.setNextUpBday(currentNextUpBday);
                //         self.setNextUpBdayName(currentNextUpBdayName);
                //         self.setBdaysUnder30(bdaysUnder30Array);
                //         self.setBirthdays(bdaysDateArray);
                //         self.setFamilyMembers(membersWithoutMe);
                //         self.setLoading(false);
                //         self.setLoaded(true);
                //     }
                // });
            },
            setActiveGroup: (groupId) => {
                self.activeGroupId = groupId;
                self.setActiveGroupName(groupId);
                UserStore.setInviteUrl("list.gifts/invite/" + groupId);
                if (UserStore.user.role !== "guest") {
                    MyListStore.loadGifts(groupId, UserStore.user._id);
                }
                self.setFamily(groupId, UserStore.user._id);
            },
            setActiveGroupName: (groupId) => {
                let name = UserStore.user.groups.length !== 0 ? UserStore.user.groups.find((group) => { return group.id === groupId }).name : "";
                self.activeGroupName = name;
            },
            setNextUpBday: (day) => {
                self.nextBirthday = day;
            },
            setNextUpBdayName: (name) => {
                self.nextBirthdayName = name;
            },
            setBirthdays: (birthdays) => {
                self.birthdays = birthdays;
            },
            setBdaysUnder30: (birthdays) => {
                self.bdaysUnder30 = birthdays;
            },
            setLoading: (loading) => {
                self.loading = loading;
            },
            setLoaded: (loaded) => {
                self.loaded = loaded;
            },
            setFamilyMembers: (members) => {           
                self.family = members;
            }

        }
    });


const FamilyStore = FamilyStoreModel.create({
    loading: false,
    loaded: false,
    family: [],
    activeGroup: { id: "guest", name: "guest" }
});

export default FamilyStore;