import Gift from '../models/GiftModel';
import { types } from "mobx-state-tree";
import { getGifts, getGiftsByGroup } from '../api_calls/gift';

const MyListModel = types
    .model('MyList', {
        loading: types.boolean,
        loaded: types.boolean,
        gifts: types.optional(types.array(Gift), []),
        myList: types.optional(types.array(Gift), []),      
    })
    .views(self => {
        return {
            isLoaded: () => self.loaded,
            isLoading: () => self.loading
        }
    })
    .actions(self => {
        return {
            setLoading: (loading) => {
                self.loading = loading;
            },
            setLoaded: (loaded) => {
                self.loaded = loaded;
            },
            loadGifts: async (family, me) => {
                self.setLoading(true);
                self.setLoaded(false);
                getGifts(family).then((gifts) => {
                    let giftsToUse = [];
                    if (gifts && gifts.length) {
                        giftsToUse = gifts;
                    }
                    getGiftsByGroup(family).then((groupGifts) => {
                        let groupGiftsToUse = [];
                        if (groupGifts && groupGifts.length) {
                            groupGiftsToUse = groupGifts;
                        }
                        let allGifts = giftsToUse.concat(groupGiftsToUse);
                        let uniqGifts = [];
                        let uniqGiftIds = [];
                        allGifts.forEach((gift) => {
                            if  (!uniqGiftIds.includes(gift._id)) {
                                uniqGiftIds.push(gift._id);
                                uniqGifts.push(gift);
                            }
                        });
                        self.setGifts(uniqGifts, me);
                    });
                });
            },
            setGifts: (gifts, me) => {
                if (gifts !== null) {
                    self.gifts = gifts;
                    self.setMyList(gifts, me);
                }
            },            
            setMyList: (gifts, me) => {
                let myListArray = [];
                gifts.forEach(gift => {
                    if (gift.wisher === me) {
                        myListArray.push(gift);
                    }
                });
                self.myList = myListArray;
                self.setLoading(false);
                self.setLoaded(true);
            }
        }
    });

const MyListStore = MyListModel.create({
    loading: false,
    loaded: false
});
export default MyListStore;