import React, { Component } from 'react';
import './App.css';
import CoreApp from './components/CoreApp';
import MyHeader from './components/MyHeader';
import GuestHomePage from './components/GuestHomePage';
import { observer } from 'mobx-react';
import { getUser } from './api_calls/user';
import Modal from 'react-modal';
import { Layout, Icon, message, Button, Input } from 'antd';

const LS_KEY = 'mm-login-demo:auth';
Modal.setAppElement('#root')
@observer(["UserStore", "AuthStore"])
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingModalOpen: true
    };
  }
  componentWillMount() {
    // Access token is stored in localstorage
    // console.log("LS: " + localStorage.getItem(LS_KEY));
    let auth;
    let self = this;
    if (localStorage.getItem(LS_KEY) !== null) {
      auth = JSON.parse(localStorage.getItem(LS_KEY));
      getUser(auth.accessToken).then((user) => {
        self.props.UserStore.setUser(user).then(() => { self.setState({ loadingModalOpen: false }) })
      });
    } else {
      self.setState({ loadingModalOpen: false });
    }
    this.setState({
      auth
    });
    this.props.AuthStore.authUser(auth)
  }

  handleLoggedIn = auth => {
    let self = this;
    localStorage.setItem(LS_KEY, JSON.stringify(auth));
    this.props.AuthStore.authUser(auth);
    message.success("Successfully logged in!");
    getUser(auth.accessToken).then((user) => {
      self.props.UserStore.setUser(user);
    });
  };

  handleLoggedOut = () => {
    localStorage.removeItem(LS_KEY);
    this.props.UserStore.setUser({ _id: "guest", role: "guest", groups: [], name: "guest" })
    this.setState({ auth: undefined });
  };

  componentDidMount() {
    const jq = document.createElement("script");
    jq.src = "https://code.jquery.com/jquery-3.4.1.min.js"
    jq.async = true;
    document.body.appendChild(jq);
  }

  render() {
    return (
      <div className="App" style={{ textAlign: "center", width: '100vw' }}>
        <Modal className="loadingModal" style={{
          overlay: {
            backgroundColor: '#0b5284',
            width: "100%",
            height: "100%",
            border: "none"

          }, content: { background: "#0b5284" }
        }} isOpen={this.state.loadingModalOpen
        }>
          <div style={{ background: "#0b5284", textAlign: "center", border: "none" }}>
            <div style={{
              position: "absolute",
              top: "20%",
              background: "#0b5284",
              color: "white",
              fontSize: "20pt",
              textAlign: "center"
            }} className="AppFont">Unwrapping...</div>

            <Icon className="bounce" style={{
              position: "absolute",
              top: "50%",
              background: "#0b5284",
              color: "white",
              fontSize: "80pt"
            }} type="gift" />
          </div>
        </Modal>
        <img alt="Logo" style={{ position: "absolute", top: "2px", left: "2px" }} width="45px" height="45px" src={require("./assets/gift-flat.png")} />
        <div className="AppFont"> list.gifts admin</div>
        {this.props.UserStore.user.role === "guest" ?
          <GuestHomePage onLoggedIn={this.handleLoggedIn} /> :
          <div>
            <Layout className="layout">
              <MyHeader onLoggedOut={this.handleLoggedOut} />
            </Layout>
            <CoreApp />
          </div>}
      </div>
    );
  }
}
export default App;